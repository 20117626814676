<template>
  <div>
    <div class="SecondTitle">
      <span>
        {{ $t("enterNumber.secondTitle") }}
      </span>
    </div>
    <div v-show="showLineTCPPdiv">
        <div style="height: 48px; display: flex; justify-content: center;">
            <van-button class="line-btn" @click="gotoThirdPartAuth('line')">
                <img class="line-icon" src="../../assets/img/line.svg"/>
            </van-button>
        </div>
        <div class="line-tips-div">
            <span class="line-tips">By clicking "Line", I agree to the Term Condition and Privacy Policy</span>
        </div>
        <div class="or-div">
            <div class="or-inner-div">
                <div class="or-line"></div>
                <span class="or-tips">OR</span>
                <div class="or-line"></div>
            </div>
        </div>
    </div>

    <div class="tips-div">
      <span>
        {{ $t("enterNumber.tips") }}
      </span>
    </div>

    <van-form :onChange="saveInfo()">
      <div style="margin-top: 15px">
        <div
          style="
            width: 25%;
            display: inline-block;
            vertical-align: middle;
            margin: 0 auto;
          "
        >
          <van-image
            style="vertical-align: middle; float: left"
            :src="imagePath"
          />
        </div>
        <div
          style="
            width: 5%;
            display: inline-block;
            vertical-align: middle;
            margin: 0 auto;
          "
        >
          <div
            style="width: 1px; float: right; height: 20px; background: #000000"
          ></div>
        </div>
        <div
          style="
            width: 70%;
            display: inline-block;
            vertical-align: middle;
            margin: 0 auto;
          "
        >
          <van-field
            type="number"
            v-model="phoneNumber"
            center
            :placeholder="this.$t('enterNumber.phonePlaceHolder')"
            :maxlength="9"
            :rules="[
              {
                pattern,
                trigger: 'onBlur',
                message: this.$t('enterNumber.phoneRuleMessage'),
              },
            ]"
          />
          <van-number-keyboard
            v-model="phoneNumber"
            :show="showKeyboard"
            @blur="showKeyboard = false"
          />
        </div>
      </div>

      <van-divider :style="{ borderColor: '#000000', margin: '0' }" />
      <div class="small-tips">
        <span>
          {{ $t("enterNumber.smallTips") }}
        </span>
      </div>
      <div class="second-tips">
        <div>
          <input class="agreeCheckBox" type="checkbox" v-model="agreeChecked" />
        </div>
        <div
          v-if="brandConfig.name == 'tomford' || brandConfig.name == 'lelabo'"
          class="agreeStatement"
          v-html="
            $t('enterNumber.readPrivacyPolicyForTFAndLL').format(
              brandConfig.display,
              privacyPolicyLink
            )
          "
        ></div>
        <div
          v-else
          class="agreeStatement"
          v-html="
            $t('enterNumber.readPrivacyPolicy').format(
              brandConfig.display,
              privacyPolicyLink
            )
          "
        ></div>
      </div>
      <van-button
        :disabled="checkPhone()"
        size="large"
        round
        native-type="submit"
        class="button-getotp"
        @click="toOtp"
      >
        {{ $t("enterNumber.getOTP") }}
      </van-button>
    </van-form>
  </div>
</template>

<script>
import {
  Form,
  DropdownMenu,
  DropdownItem,
  Divider,
  Button,
  Field,
  NumberKeyboard,
  PasswordInput,
  Toast,
  Dialog
} from "vant";
import { Image as VanImage } from "vant";
import { generateOtp, getSession, getAuthorizeUrl, relateSearch } from "@/api/eformApi";
// import { generateOtp, getAuthorizeUrl,  } from "@/api/eformApi";

import { zipEncode } from "@/utils/zipcoder";
export default {
  name: "EnterNumber",
  components: {
    [Form.name]: Form,
    [NumberKeyboard.name]: NumberKeyboard,
    [PasswordInput.name]: PasswordInput,
    [VanImage.name]: VanImage,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Divider.name]: Divider,
    [Button.name]: Button,
    [Field.name]: Field,
    [Dialog.Component.name]: Dialog.Component,
  },
  props: {
    brandConfig: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  created() {
    this.imagePath = require("/public/img/" + "th" + "-areacode.png");  
    console.log("enasd", this.$root.$i18n.locale);
    this.$root.$i18n.locale = this.$route.params.lang;
    this.countryPath = this.$route.params.countryPath;

    switch (this.$route.params.countryPath) {
      case "sg":
        this.areaCode = 65;
        this.pattern = this.sgPattern;
        break;
      case "TH":
        this.areaCode = 66;
        this.pattern = this.thPattern;
        break;
      default:
        this.areaCode = 66;
        this.pattern = this.myPattern;
        break;
    }
    if (localStorage.getItem("enterNumberInfo")) {
      this.phoneNumber = localStorage.getItem("enterNumberInfo");
    }
  },
  data() {
    return {
      phoneNumber: "",
      linkToken: "",
      showKeyboard: false,
      imagePath: "",
      pattern: /^$|^([689]{1})+([0-9]{7})$/,
      sgPattern: /^$|^([689]{1})+([0-9]{7})+$/,
      thPattern: /^$|^([0-9]{9})+$/,
      myPattern: /^$|^([0-9]{10})+$/,
      areaCode: 66,
      agreeChecked: false,
      brandName: "",
      country: "",
      countryPath: "",
      showLineTCPPdiv:
        this.$route.params.countryPath == "TH" && !this.$route.params.tpid,
      tpid: this.$route.params.tpid, //授权绑定成功后返回tpid，传递给下一个路由对象
    };
  },
  computed: {
    privacyPolicyLink() {
      if (this.brandConfig.privacyPolicy) {
        return `<span><a href="${this.brandConfig.privacyPolicy}">Privacy Policy</a></span>`;
      } else {
        return `<span>Privacy Policy</span>`;
      }
    },
  },
  mounted() {},
  methods: {
    toOtp() {
      getSession({
          mobile: this.phoneNumber,
          brand: this.$route.params.brand,
          country: this.$route.params.countryPath,
      }).then(res => {
        if(res.success){
          relateSearch({
            mobile: this.phoneNumber,
            country: this.$route.params.countryPath,
            brand: this.$route.params.brand,
            sessionKey: res.data,
          }).then(res=>{
            console.log(res);
            if(!res.success){
              Dialog.alert({
                  message: this.$t('registration.alreadyRP'),
                  confirmButtonText: this.$t('registration.confirm')
              })
              return 
            }
            sessionStorage.setItem("phoneNumber", this.areaCode + this.phoneNumber);
            console.log(this.$route.params.countryPath);
            generateOtp({
              // TODO: replace with real phone number
              phoneno: this.areaCode + this.phoneNumber,
              country: this.$route.params.countryPath,
              brandName: this.brandConfig.name,
              brandAbbr: this.brandConfig.abbr,
            })
              .then((res) => {
                if (res.success && res.data.request_id) {
                  console.log(res.data.request_id);
                  if (this.countryPath == "TH") {
                    console.log(res.data.request_id);
                    sessionStorage.setItem("th_request_id", res.data.request_id);
                  }
                  Toast.success(this.$t("enterNumber.toastSuccess"));
                  this.$router.push({
                    name: "EnterOpt",
                    params: {
                      //TODO: replace with real phone number
                      phoneNumber: this.areaCode + this.phoneNumber,
                      countryPath: this.$route.params.countryPath,
                      brandName: this.brandConfig.name,
                      otpcode: res.data.ref_code,
                      lang: this.$route.params.lang,
                      storeId: this.$route.query.storeId,
                      tpid: this.tpid,
                    },
                    query: {
                      phoneNumber: this.areaCode + this.phoneNumber,
                      storeId: this.$route.query.storeId,
                    },
                  });
                } else {
                  console.log(res.success);
                  // Toast.fail(res.data)
                  Toast.fail(this.$t("enterNumber.toastFail"));
                  //TODO: mock need to be deleted
                  // this.$router.push({
                  //     name: 'EnterOpt',
                  //     params: {
                  //         phoneNumber: this.areaCode+this.phoneNumber,
                  //         country: localStorage.getItem('countryPath')
                  //     }
                  // })
                }
              })
              .catch(() => {
                Toast.fail(this.$t("common.error"));
              });
          })
        }
      }).catch(() => {
          Toast.fail(this.$t('common.error'))
      });
      

      // sessionStorage.setItem("phoneNumber", this.areaCode + this.phoneNumber);
      // console.log(this.$route.params.countryPath);
      // generateOtp({
      //   // TODO: replace with real phone number
      //   phoneno: this.areaCode + this.phoneNumber,
      //   country: this.$route.params.countryPath,
      //   brandName: this.brandConfig.name,
      //   brandAbbr: this.brandConfig.abbr,
      // })
      //   .then((res) => {
      //     if (res.success && res.data.request_id) {
      //       console.log(res.data.request_id);
      //       if (this.countryPath == "TH") {
      //         console.log(res.data.request_id);
      //         sessionStorage.setItem("th_request_id", res.data.request_id);
      //       }
      //       Toast.success(this.$t("enterNumber.toastSuccess"));
      //       this.$router.push({
      //         name: "EnterOpt",
      //         params: {
      //           //TODO: replace with real phone number
      //           phoneNumber: this.areaCode + this.phoneNumber,
      //           countryPath: this.$route.params.countryPath,
      //           brandName: this.brandConfig.name,
      //           otpcode: res.data.ref_code,
      //           lang: this.$route.params.lang,
      //           storeId: this.$route.query.storeId,
      //           tpid: this.tpid,
      //         },
      //         query: {
      //           phoneNumber: this.areaCode + this.phoneNumber,
      //           storeId: this.$route.query.storeId,
      //         },
      //       });
      //     } else {
      //       console.log(res.success);
      //       // Toast.fail(res.data)
      //       Toast.fail(this.$t("enterNumber.toastFail"));
      //       //TODO: mock need to be deleted
      //       // this.$router.push({
      //       //     name: 'EnterOpt',
      //       //     params: {
      //       //         phoneNumber: this.areaCode+this.phoneNumber,
      //       //         country: localStorage.getItem('countryPath')
      //       //     }
      //       // })
      //     }
      //   })
      //   .catch(() => {
      //     Toast.fail(this.$t("common.error"));
      //   });
      // // Toast.success(this.$t('enterNumber.toastSuccess'))
      // // this.$router.push({
      // //     name: 'EnterOpt',
      // //     params: {
      // //         //TODO: replace with real phone number
      // //         phoneNumber: this.areaCode + this.phoneNumber,
      // //         countryPath: this.$route.params.countryPath,
      // //         brand: this.$route.params.brand,
      // //         lang: this.$route.params.lang,
      // //         storeId: this.$route.query.storeId
      // //     },
      // //     query: {
      // //         phoneNumber: this.areaCode + this.phoneNumber,

      // //     }
      // // })
    },
    checkPhone() {
      switch (this.$route.params.countryPath) {
        case "sg":
          if (
            this.phoneNumber.length === 0 ||
            !this.sgPattern.test(this.phoneNumber) ||
            !this.agreeChecked
          ) {
            return true;
          } else {
            return false;
          }
        case "TH":
          if (
            this.phoneNumber.length === 0 ||
            !this.thPattern.test(this.phoneNumber) ||
            !this.agreeChecked
          ) {
            return true;
          } else {
            return false;
          }
        default:
          return true;
      }
    },
    saveInfo() {
      console.log(this.phoneNumber);
      localStorage.setItem("enterNumberInfo", this.phoneNumber);
    },
    gotoThirdPartAuth(thirdPart) {
      if (this.tpid) {
        console.log("tpid exist, skip bind");
        //skip if binded
        return;
      }
      let country = this.$route.params.countryPath;
      let brand = this.$route.params.brand;
      getAuthorizeUrl({
        thirdPart: thirdPart,
        country: country,
        brand: brand,
        hostPath: window.location.href.split("#")[0],
        nextRouter: zipEncode(
          JSON.stringify({
            name: "EnterNumber",
            params: this.$route.params,
            query: this.$route.query,
          })
        ),
      })
        .then((res) => {
          if (res.success) {
            console.log(
              `${res.data.tpid} redirect to ${thirdPart} authorize url: ${res.data.authorizeUrl}`
            );
            window.location.href = res.data.authorizeUrl;
          } else {
            console.log(res.errMsg);
            Toast.fail({
              type: "fail",
              message: res.errMsg,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fail({
            type: "fail",
            message: "Authorize url get failed",
          });
        });
    },
  },
};
</script>

<style scoped>
* {
  font-family: Helvetica-Regular !important;
}

>>> .van-popup--top {
  width: 25%;
  padding-left: 16px;
}

.button-getotp {
  background: black;
  color: white;
  border-radius: 7px;
  margin-top: 4%;
}

>>> .van-password-input__item {
  border-radius: 5px;
}

.line-btn{
    height: 48px; 
    width: 48px; 
    background-color: #00b900;
    border-radius: var(--iam-social-button-radius);
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
}

.line-tips-div{
    display: flex; 
    justify-content: center; 
    margin-top: 20px;
    margin-bottom: 10px;
}

.line-tips{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    margin-left: 10px;
    margin-right: 10px;
    word-break: break-all;
}

.line-icon{
    width: 24px; 
    height: 24px; 
    vertical-align: middle;
}


.tips-div{
    display: flex; 
    justify-content: center; 
    margin-top: 10px;
    margin-bottom: 10px;
}

.email-tips{
    /* width: 284px;
    height: 47px; */
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
}

.or-div{
    display: flex; 
    justify-content: center; 
    height: 24px;
}

.or-inner-div{
    /* width: 299px;  */
    width: 100%; 
    border: 1px;
    display: flex;
    /** 使OR线水平居中 */
    justify-content: center;
    align-items: center;
}

.or-line{
    /* width: 124px; */
    width: 60%;
    height: 0px;
    border-bottom: 1px solid #cccccc;
}

.or-tips{
    width: 24px;
    height: 24px;
    margin: 0px 15px;
    color: #cccccc;
}

</style>
