var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"SecondTitle"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("enterNumber.secondTitle"))+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLineTCPPdiv),expression:"showLineTCPPdiv"}]},[_c('div',{staticStyle:{"height":"48px","display":"flex","justify-content":"center"}},[_c('van-button',{staticClass:"line-btn",on:{"click":function($event){return _vm.gotoThirdPartAuth('line')}}},[_c('img',{staticClass:"line-icon",attrs:{"src":require("../../assets/img/line.svg")}})])],1),_vm._m(0),_vm._m(1)]),_c('div',{staticClass:"tips-div"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("enterNumber.tips"))+" ")])]),_c('van-form',{attrs:{"onChange":_vm.saveInfo()}},[_c('div',{staticStyle:{"margin-top":"15px"}},[_c('div',{staticStyle:{"width":"25%","display":"inline-block","vertical-align":"middle","margin":"0 auto"}},[_c('van-image',{staticStyle:{"vertical-align":"middle","float":"left"},attrs:{"src":_vm.imagePath}})],1),_c('div',{staticStyle:{"width":"5%","display":"inline-block","vertical-align":"middle","margin":"0 auto"}},[_c('div',{staticStyle:{"width":"1px","float":"right","height":"20px","background":"#000000"}})]),_c('div',{staticStyle:{"width":"70%","display":"inline-block","vertical-align":"middle","margin":"0 auto"}},[_c('van-field',{attrs:{"type":"number","center":"","placeholder":this.$t('enterNumber.phonePlaceHolder'),"maxlength":9,"rules":[
            {
              pattern: _vm.pattern,
              trigger: 'onBlur',
              message: this.$t('enterNumber.phoneRuleMessage'),
            },
          ]},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('van-number-keyboard',{attrs:{"show":_vm.showKeyboard},on:{"blur":function($event){_vm.showKeyboard = false}},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1)]),_c('van-divider',{style:({ borderColor: '#000000', margin: '0' })}),_c('div',{staticClass:"small-tips"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("enterNumber.smallTips"))+" ")])]),_c('div',{staticClass:"second-tips"},[_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.agreeChecked),expression:"agreeChecked"}],staticClass:"agreeCheckBox",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.agreeChecked)?_vm._i(_vm.agreeChecked,null)>-1:(_vm.agreeChecked)},on:{"change":function($event){var $$a=_vm.agreeChecked,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.agreeChecked=$$a.concat([$$v]))}else{$$i>-1&&(_vm.agreeChecked=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.agreeChecked=$$c}}}})]),(_vm.brandConfig.name == 'tomford' || _vm.brandConfig.name == 'lelabo')?_c('div',{staticClass:"agreeStatement",domProps:{"innerHTML":_vm._s(
          _vm.$t('enterNumber.readPrivacyPolicyForTFAndLL').format(
            _vm.brandConfig.display,
            _vm.privacyPolicyLink
          )
        )}}):_c('div',{staticClass:"agreeStatement",domProps:{"innerHTML":_vm._s(
          _vm.$t('enterNumber.readPrivacyPolicy').format(
            _vm.brandConfig.display,
            _vm.privacyPolicyLink
          )
        )}})]),_c('van-button',{staticClass:"button-getotp",attrs:{"disabled":_vm.checkPhone(),"size":"large","round":"","native-type":"submit"},on:{"click":_vm.toOtp}},[_vm._v(" "+_vm._s(_vm.$t("enterNumber.getOTP"))+" ")])],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-tips-div"},[_c('span',{staticClass:"line-tips"},[_vm._v("By clicking \"Line\", I agree to the Term Condition and Privacy Policy")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"or-div"},[_c('div',{staticClass:"or-inner-div"},[_c('div',{staticClass:"or-line"}),_c('span',{staticClass:"or-tips"},[_vm._v("OR")]),_c('div',{staticClass:"or-line"})])])
}]

export { render, staticRenderFns }